@if($app-katalog==false){
	#complaint-page {
		padding: 20px;
		font-size: 13px;
		padding-bottom: 35px;

		.center {
			text-align: center;
		}

		p {
			margin-bottom: 15px;
		}

		a {
			text-decoration: underline;
		}

		.btn {
			text-decoration: none;
		}

		ol,
		ul {
			padding-left: 20px;
			margin-bottom: 15px;
		}

		ul li {
			list-style: disc;
		}

		.complaint-header {
			margin-top: 40px;
		}

		.complaint-info-customer,
		.complaint-info-entrepreneur {
			display: none;
		}

		.complaint-options {
			overflow: hidden;
			margin-top: 40px;

			.btn {
				width: 380px;
			}

			.column {
				text-align: center;
				width: 50%;
				float: left;
			}

			.steps-small {
				margin: 0 auto;
			}

			.steps-3 {
				max-width: 300px;

				.step {
					width: 33.33%;
				}
			}

			.steps-5 {
				max-width: 450px;

				.step {
					width: 20%;
				}
			}

			.step {
				float: left;
				text-align: center;
				position: relative;

				&:not(:last-child):after {
					content: "";
					@extend .sprite;
					@extend .sprite-arrow;
					position: absolute;
					top: 15px;
					right: -5px;
				}
			}

			.step-title {
				margin: 10px 0 15px;
				text-transform: uppercase;
				display: block;
				font-weight: bold;
				font-size: 12px;
			}
		}

		section {
			padding: 0 80px 40px;

			&:last-child {
				border: 0;
			}

			p {
				font-size: 15px;
				letter-spacing: -0.3px;
				line-height: 16px;
				padding-top: 20px;
			}

			&.complaint-content {
				padding-top: 40px;
			}
		}

		#complaint_number {
			outline: none;
		}

		h2 {
			font-weight: bold;
			font-size: 17px;
			font-weight: bold;
			letter-spacing: 0.3px;
		}

		div {
			font-family: $font-default;
		}

		.steps {
			margin: 35px auto 5px;
			font-size: 0;

			.step {
				display: inline-block;
				font-weight: bold;
				text-transform: uppercase;
				width: 150px;
				padding-top: 88px;
				text-align: center;
				position: relative;
				font-size: 16px;
				vertical-align: top;
				margin: 0 11px;
				line-height: 20px;
				letter-spacing: 0.8px;

				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 50%;
					margin-left: -40px;
					width: 79px;
					height: 79px;
					display: inline-block;
					background-repeat: no-repeat;
					background-position: center center;
				}

				&::after {
					content: "";
					position: absolute;
					right: -21px;
					top: 30px;
					display: inline-block;
					background-image: url($img-url+'/cms/arrows-double.png');
					background-repeat: no-repeat;
					width: 20px;
					height: 20px;
				}

				&:first-child {
					margin-left: 0;
					margin-right: 50px;

					&::after {
						right: -61px;
					}
				}

				&:last-child {
					margin-right: 0;
					margin-left: 50px;

					&::after {
						display: none;
					}
				}

				&.customer {
					width: 79px;
					line-height: 31px;

					&::before {
						background-image: url($img-url+'cms/customer.png');
					}
				}

				&.service {
					&::before {
						background-image: url($img-url+'cms/service.png');
					}
				}

				&.customer-service {
					&::before {
						background-image: url($img-url+'cms/customer-service.png');
					}
				}
			}

			&.columns-3 {
				width: 430px;
			}

			&.columns-5 {
				margin-top: 65px;

				.step {
					margin: 0 13px;

					&:first-child {
						margin-left: 0;
						margin-right: 23px;

						&::after {
							right: -48px;
						}
					}

					&:last-child {
						margin-right: 0;
						margin-left: 27px;
					}
				}
			}
		}

		.complaint-brand-box {}

		.fields {
			width: 430px;
			margin: 0 auto;
			vertical-align: middle;
			font-size: $font-default-size;
			position: relative;
		}

		#brand-label {
			display: none;
			font-size: 15px;
			line-height: 1.6;
		}

		#brand-form:after {
			content: "";
			clear: both;
			display: block;
		}

		#brand-name {
			border: 1px solid #ccc;
			padding: 8px 16px;
			font-size: 13px;
			float: left;
			width: 220px;
			height: 23px;
			z-index: 1;
			position: relative;
			outline: none;
		}

		#brand-list {
			top: 41px;
			padding: 0;
			z-index: 0;
			left: 0;
			width: 254px;
			position: absolute;
			background: #fff;
			max-height: 200px;
			overflow-y: auto;

			li {
				border-left: 1px solid #ccc;
				border-right: 1px solid #ccc;
				border-bottom: 1px solid #ccc;
				font-size: 13px;
				list-style: none;
				margin: 0;
				padding: 5px 8px;
				display: none;
				cursor: pointer;

				&:hover {
					background: #eee;
				}
			}
		}

		#brand-button {
			@extend %btn-second;
			border: 0;
			float: left;
			width: 85px;
			margin: 0 15px;
			font-size: 14px;
			padding: 0 11px;
			line-height: 41px;
			text-transform: uppercase;

			&.not-active {
				@extend %btn-fourth;
				cursor: default;
			}
		}

		#brand-links {
			.brand-item {
				max-width: 430px;
				margin: 0 auto;
				display: none;
				font-size: 14px;
				padding: 0;

				&.active {
					display: block;
				}

				a {
					@extend %btn;
					@extend %btn-second;
					text-transform: uppercase;
					display: inline-block;
					margin: 15px 15px 8px 0;
					padding: 9px 11px 8px;
					font-weight: $font-weight-bold;
				}
			}

			#brand-item-0 {
				text-align: center;
				max-width: 100%;
				padding: 15px;
				background: #daeef3;
				margin-top: 20px;

				h3 {
					font-size: 16px;
				}

				p {
					padding: 0;
					margin: 10px 0;
					font-size: 13px;
				}
			}
		}

		#brand-description {
			display: table-cell;
			vertical-align: middle;
			font-size: $font-default-size;
			padding-top: 22px;
			padding-left: 15px;
		}

		.form-box {
			text-align: center;
			margin: 50px 0 30px;
		}

		.form-button {
			text-transform: uppercase;
			color: #fff;
			font-size: 16px;
			padding: 11px 16px;
			@extend %btn-third;
		}

		.address-box {
			display: inline-block;
			width: 492px;
			font-size: 15px;
			line-height: 16px;
			padding: 38px 20px 20px 0;
			vertical-align: top;

			.highlight {
				font-weight: bold;
			}
		}

		.actions-box {
			display: inline-block;
			font-size: 15px;
			line-height: 16px;
			padding: 38px 0 20px;
			vertical-align: top;

			li {
				line-height: 26px;

				&:before {
					content: "";
					margin-right: 5px;
					vertical-align: middle;
					background-image: url($img-url+'/cms/pdf-ico.png');
					width: 17px;
					height: 18px;
					display: inline-block;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
		}
	}

	#complaint-form {
		.row {
			width: 790px!important;

			label {
				font-size: 14px;
				line-height: 1.2;

				&[for=complaint-reason-missing] {
					width: 175px;
				}
			}

			&.hidden-input {
				display: none;
			}

			.complaint-date-field {
				width: 100px;
			}

			#complaint-other-phone,
			#complaint-phone {
				width: 150px;
			}

			#complaint-house-number,
			#complaint-other-house-number {
				width: 70px;
			}

			label[for="complaint-flat-number"]:not(.error),
			label[for="complaint-other-flat-number"]:not(.error) {
				margin-left: 20px;
				margin-right: 10px;
			}

			#complaint-flat-number,
			#complaint-other-flat-number {
				width: 59px;
			}

			#complaint-other-zipCode,
			#complaint-zipCode {
				width: 70px;
			}

			.ui-datepicker-trigger {
				margin-left: 8px;
			}

			#complaint-attachments-select {
				width: 150px;
				color: transparent;
			}

			#complaint-attachments-info {
				float: right;
				width: 160px;
				margin-right: -65px;
				border-left: 1px solid #ccc;
				padding-left: 10px;
			}

			#complaint-attachments-list {
				float: left;
				clear: both;
				margin-top: 10px;

				li {
					list-style: none;
					margin-left: 0;
				}

				.remove-attachement {
					color: #ff0000;
					padding-left: 5px;
					cursor: pointer;
					float: right;
				}
			}

			#complaint-equipment-missing-description {
				width: 100%;
			}

			&.equipment-missing-selected {
				#complaint-equipment-missing-description {
					display: block;
				}
			}
		}

		#complaint-legal-basis-box {
			display: none;

			#complaint-legal-basis-statute_label,
			label[for=complaint-legal-basis-statute] {
				display: none;
			}

			#complaint-legal-basis-warranty_label,
			label[for=complaint-legal-basis-warranty] {
				display: none;
			}

			&.show-statute {
				display: block;

				#complaint-legal-basis-statute_label,
				label[for=complaint-legal-basis-statute] {
					display: block;
				}
			}

			&.show-warranty {
				display: block;

				#complaint-legal-basis-warranty_label,
				label[for=complaint-legal-basis-warranty] {
					display: block;
				}
			}
		}

		#complaint-request-box {
			display: none;

			#complaint-request-cash_label,
			#complaint-request-civil_label,
			#complaint-request-discount_label,
			#complaint-request-exchange_label,
			#complaint-request-fix_label,
			#complaint-request-guarantee_label,
			label[for=complaint-request-cash],
			label[for=complaint-request-civil],
			label[for=complaint-request-discount],
			label[for=complaint-request-exchange],
			label[for=complaint-request-fix],
			label[for=complaint-request-guarantee] {
				display: none;
			}

			&.show-guarantee {
				display: block;

				#complaint-request-guarantee_label,
				label[for=complaint-request-guarantee] {
					display: block;
				}
			}

			&.show-civil {
				display: block;

				#complaint-request-civil_label,
				label[for=complaint-request-civil] {
					display: block;
				}
			}

			&.show-statute {
				display: block;

				#complaint-request-cash_label,
				#complaint-request-discount_label,
				#complaint-request-exchange_label,
				#complaint-request-fix_label,
				label[for=complaint-request-cash],
				label[for=complaint-request-discount],
				label[for=complaint-request-exchange],
				label[for=complaint-request-fix] {
					display: block;
				}
			}
		}

		.section-header {
			font-size: 14px;
			margin-bottom: 5px;
		}

		#personal-data-box {
			margin-top: 50px;
		}

		button {
			margin-top: 20px;
		}
	}

	.sprite {
		background-image: url($img-url+'cms/spritesheet.png');
		background-repeat: no-repeat;
		display: inline-block;
	}

	.sprite-arrow {
		width: 11px;
		height: 9px;
		background-position: -5px -5px;
	}

	.sprite-shop {
		width: 39px;
		height: 39px;
		background-position: -26px -5px;
	}

	.sprite-user {
		width: 39px;
		height: 39px;
		background-position: -75px -5px;
	}

	.sprite-wrench {
		width: 39px;
		height: 39px;
		background-position: -5px -54px;
	}
}
