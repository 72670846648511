#code-complaint {
	font-size: 14px;
	.header {
		margin-bottom: 20px!important;
	}
	.list {
		& > li {
			list-style-type: disc;
			margin-left: 15px!important;
			margin-bottom: 20px;
		}
	}
	a {
		text-decoration: underline;
	}
}
