%icon {
	font-family: 'iconFont'!important;
	speak: none;
	display: inline-block;
	font-display: block;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align:middle;
	color: inherit;
	&:hover{
		text-decoration:none;
	}
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%icon-modal-close{
	@extend %icon;content: "\E001";
}
%icon-360{
	@extend %icon;content: "\E002";
}
%icon-address-book-o{
	@extend %icon;content: "\E003";
}
%icon-arrow-down-long{
	@extend %icon;content: "\E004";
}
%icon-arrow-down{
	@extend %icon;content: "\E005";
}
%icon-arrow-left{
	@extend %icon;content: "\E006";
}
%icon-arrow-lg{
	@extend %icon;content: "\E007";
}
%icon-arrow-profit{
	@extend %icon;content: "\E008";
}
%icon-arrow-right{
	@extend %icon;content: "\E009";
}
%icon-arrow-thick{
	@extend %icon;content: "\E00A";
}
%icon-arrow-up{
	@extend %icon;content: "\E00B";
}
%icon-arrow{
	@extend %icon;content: "\E00C";
}
%icon-barcode{
	@extend %icon;content: "\E00D";
}
%icon-bars{
	@extend %icon;content: "\E00E";
}
%icon-basket{
	@extend %icon;content: "\E00F";
}
%icon-basket2{
	@extend %icon;content: "\E010";
}
%icon-big-arrow{
	@extend %icon;content: "\E011";
}
%icon-box-add{
	@extend %icon;content: "\E012";
}
%icon-box-closed{
	@extend %icon;content: "\E013";
}
%icon-box{
	@extend %icon;content: "\E014";
}
%icon-bug{
	@extend %icon;content: "\E015";
}
%icon-calculator{
	@extend %icon;content: "\E016";
}
%icon-callCenter{
	@extend %icon;content: "\E017";
}
%icon-caret-down{
	@extend %icon;content: "\E018";
}
%icon-caret-left{
	@extend %icon;content: "\E019";
}
%icon-caret-right{
	@extend %icon;content: "\E01A";
}
%icon-caret-up{
	@extend %icon;content: "\E01B";
}
%icon-chain{
	@extend %icon;content: "\E01C";
}
%icon-chat{
	@extend %icon;content: "\E01D";
}
%icon-chat2{
	@extend %icon;content: "\E01E";
}
%icon-check{
	@extend %icon;content: "\E01F";
}
%icon-checkbox-checked{
	@extend %icon;content: "\E020";
}
%icon-checkbox-unchecked{
	@extend %icon;content: "\E021";
}
%icon-checked{
	@extend %icon;content: "\E022";
}
%icon-clipboard{
	@extend %icon;content: "\E023";
}
%icon-clock{
	@extend %icon;content: "\E024";
}
%icon-close{
	@extend %icon;content: "\E025";
}
%icon-close2{
	@extend %icon;content: "\E026";
}
%icon-cloud{
	@extend %icon;content: "\E027";
}
%icon-compare{
	@extend %icon;content: "\E028";
}
%icon-copy{
	@extend %icon;content: "\E029";
}
%icon-credit-card{
	@extend %icon;content: "\E02A";
}
%icon-delivery-info{
	@extend %icon;content: "\E02B";
}
%icon-delivery-shop{
	@extend %icon;content: "\E02C";
}
%icon-delivery{
	@extend %icon;content: "\E02D";
}
%icon-doc{
	@extend %icon;content: "\E02E";
}
%icon-edit{
	@extend %icon;content: "\E02F";
}
%icon-ellipsis-v{
	@extend %icon;content: "\E030";
}
%icon-envelope-o{
	@extend %icon;content: "\E031";
}
%icon-error{
	@extend %icon;content: "\E032";
}
%icon-exclamation-triangle{
	@extend %icon;content: "\E033";
}
%icon-eye-slash{
	@extend %icon;content: "\E034";
}
%icon-eye{
	@extend %icon;content: "\E035";
}
%icon-facebook{
	@extend %icon;content: "\E036";
}
%icon-favorite{
	@extend %icon;content: "\E037";
}
%icon-favorites{
	@extend %icon;content: "\E038";
}
%icon-file-text2{
	@extend %icon;content: "\E039";
}
%icon-finger-down{
	@extend %icon;content: "\E03A";
}
%icon-finger-up{
	@extend %icon;content: "\E03B";
}
%icon-footer-phone{
	@extend %icon;content: "\E03C";
}
%icon-gift{
	@extend %icon;content: "\E03D";
}
%icon-gnc{
	@extend %icon;content: "\E03E";
}
%icon-google-plus{
	@extend %icon;content: "\E03F";
}
%icon-hand{
	@extend %icon;content: "\E040";
}
%icon-happy{
	@extend %icon;content: "\E041";
}
%icon-headphones-line{
	@extend %icon;content: "\E042";
}
%icon-headphones{
	@extend %icon;content: "\E043";
}
%icon-heart-empty{
	@extend %icon;content: "\E044";
}
%icon-heart{
	@extend %icon;content: "\E045";
}
%icon-heart2{
	@extend %icon;content: "\E046";
}
%icon-hide{
	@extend %icon;content: "\E047";
}
%icon-history{
	@extend %icon;content: "\E048";
}
%icon-identity{
	@extend %icon;content: "\E049";
}
%icon-inbox{
	@extend %icon;content: "\E04A";
}
%icon-info{
	@extend %icon;content: "\E04B";
}
%icon-information{
	@extend %icon;content: "\E04C";
}
%icon-location-pickup{
	@extend %icon;content: "\E04D";
}
%icon-location{
	@extend %icon;content: "\E04E";
}
%icon-map{
	@extend %icon;content: "\E04F";
}
%icon-marker{
	@extend %icon;content: "\E050";
}
%icon-marker2{
	@extend %icon;content: "\E051";
}
%icon-menu-hamburger-alt{
	@extend %icon;content: "\E052";
}
%icon-menu{
	@extend %icon;content: "\E053";
}
%icon-money-contract{
	@extend %icon;content: "\E054";
}
%icon-money-multibill{
	@extend %icon;content: "\E055";
}
%icon-money{
	@extend %icon;content: "\E056";
}
%icon-more-dots{
	@extend %icon;content: "\E057";
}
%icon-moz{
	@extend %icon;content: "\E058";
}
%icon-multimedia-360{
	@extend %icon;content: "\E059";
}
%icon-multimedia-photo{
	@extend %icon;content: "\E05A";
}
%icon-multimedia-video{
	@extend %icon;content: "\E05B";
}
%icon-navigation-arrow-down{
	@extend %icon;content: "\E05C";
}
%icon-navigation-before{
	@extend %icon;content: "\E05D";
}
%icon-no-calendar{
	@extend %icon;content: "\E05E";
}
%icon-notepad{
	@extend %icon;content: "\E05F";
}
%icon-office{
	@extend %icon;content: "\E060";
}
%icon-online-shop{
	@extend %icon;content: "\E061";
}
%icon-pdf{
	@extend %icon;content: "\E062";
}
%icon-person{
	@extend %icon;content: "\E063";
}
%icon-phone{
	@extend %icon;content: "\E064";
}
%icon-phone2{
	@extend %icon;content: "\E065";
}
%icon-phone3{
	@extend %icon;content: "\E066";
}
%icon-pinterest{
	@extend %icon;content: "\E067";
}
%icon-play{
	@extend %icon;content: "\E068";
}
%icon-print{
	@extend %icon;content: "\E069";
}
%icon-promotion{
	@extend %icon;content: "\E06A";
}
%icon-question{
	@extend %icon;content: "\E06B";
}
%icon-question1{
	@extend %icon;content: "\E06C";
}
%icon-radiobutton-checked{
	@extend %icon;content: "\E06D";
}
%icon-radiobutton-unchecked{
	@extend %icon;content: "\E06E";
}
%icon-reload{
	@extend %icon;content: "\E06F";
}
%icon-retweet{
	@extend %icon;content: "\E070";
}
%icon-save{
	@extend %icon;content: "\E071";
}
%icon-scroll{
	@extend %icon;content: "\E072";
}
%icon-search{
	@extend %icon;content: "\E073";
}
%icon-search2{
	@extend %icon;content: "\E074";
}
%icon-service-reclamation{
	@extend %icon;content: "\E075";
}
%icon-service1{
	@extend %icon;content: "\E076";
}
%icon-shield-empty{
	@extend %icon;content: "\E077";
}
%icon-shield{
	@extend %icon;content: "\E078";
}
%icon-shop{
	@extend %icon;content: "\E079";
}
%icon-shopping-basket{
	@extend %icon;content: "\E07A";
}
%icon-shopping-cart{
	@extend %icon;content: "\E07B";
}
%icon-shops{
	@extend %icon;content: "\E07C";
}
%icon-show{
	@extend %icon;content: "\E07D";
}
%icon-sim{
	@extend %icon;content: "\E07E";
}
%icon-small-arrow{
	@extend %icon;content: "\E07F";
}
%icon-stack{
	@extend %icon;content: "\E080";
}
%icon-star{
	@extend %icon;content: "\E081";
}
%icon-stars-fill{
	@extend %icon;content: "\E082";
}
%icon-stars-stroke{
	@extend %icon;content: "\E083";
}
%icon-stars{
	@extend %icon;content: "\E084";
}
%icon-stetoskop{
	@extend %icon;content: "\E085";
}
%icon-stock{
	@extend %icon;content: "\E086";
}
%icon-table{
	@extend %icon;content: "\E087";
}
%icon-ticket{
	@extend %icon;content: "\E088";
}
%icon-tooltip{
	@extend %icon;content: "\E089";
}
%icon-tradein{
	@extend %icon;content: "\E08A";
}
%icon-trash{
	@extend %icon;content: "\E08B";
}
%icon-truck{
	@extend %icon;content: "\E08C";
}
%icon-twitter{
	@extend %icon;content: "\E08D";
}
%icon-user-add{
	@extend %icon;content: "\E08E";
}
%icon-user-check{
	@extend %icon;content: "\E08F";
}
%icon-user-line{
	@extend %icon;content: "\E090";
}
%icon-user-outline{
	@extend %icon;content: "\E091";
}
%icon-user-search{
	@extend %icon;content: "\E092";
}
%icon-user{
	@extend %icon;content: "\E093";
}
%icon-user2{
	@extend %icon;content: "\E094";
}
%icon-video{
	@extend %icon;content: "\E095";
}
%icon-vote-down{
	@extend %icon;content: "\E096";
}
%icon-vote-up{
	@extend %icon;content: "\E097";
}
%icon-warn{
	@extend %icon;content: "\E098";
}
%icon-warn2{
	@extend %icon;content: "\E099";
}
%icon-warn3{
	@extend %icon;content: "\E09A";
}
%icon-warranty-screen{
	@extend %icon;content: "\E09B";
}
%icon-x{
	@extend %icon;content: "\E09C";
}
%icon-youtube{
	@extend %icon;content: "\E09D";
}
%icon-zoom-in{
	@extend %icon;content: "\E09E";
}
%icon-zoom-out{
	@extend %icon;content: "\E09F";
}
%icon-zoom{
	@extend %icon;content: "\E0A0";
}


$icons-names: "modal-close", "360", "address-book-o", "arrow-down-long", "arrow-down", "arrow-left", "arrow-lg", "arrow-profit", "arrow-right", "arrow-thick", "arrow-up", "arrow", "barcode", "bars", "basket", "basket2", "big-arrow", "box-add", "box-closed", "box", "bug", "calculator", "callCenter", "caret-down", "caret-left", "caret-right", "caret-up", "chain", "chat", "chat2", "check", "checkbox-checked", "checkbox-unchecked", "checked", "clipboard", "clock", "close", "close2", "cloud", "compare", "copy", "credit-card", "delivery-info", "delivery-shop", "delivery", "doc", "edit", "ellipsis-v", "envelope-o", "error", "exclamation-triangle", "eye-slash", "eye", "facebook", "favorite", "favorites", "file-text2", "finger-down", "finger-up", "footer-phone", "gift", "gnc", "google-plus", "hand", "happy", "headphones-line", "headphones", "heart-empty", "heart", "heart2", "hide", "history", "identity", "inbox", "info", "information", "location-pickup", "location", "map", "marker", "marker2", "menu-hamburger-alt", "menu", "money-contract", "money-multibill", "money", "more-dots", "moz", "multimedia-360", "multimedia-photo", "multimedia-video", "navigation-arrow-down", "navigation-before", "no-calendar", "notepad", "office", "online-shop", "pdf", "person", "phone", "phone2", "phone3", "pinterest", "play", "print", "promotion", "question", "question1", "radiobutton-checked", "radiobutton-unchecked", "reload", "retweet", "save", "scroll", "search", "search2", "service-reclamation", "service1", "shield-empty", "shield", "shop", "shopping-basket", "shopping-cart", "shops", "show", "sim", "small-arrow", "stack", "star", "stars-fill", "stars-stroke", "stars", "stetoskop", "stock", "table", "ticket", "tooltip", "tradein", "trash", "truck", "twitter", "user-add", "user-check", "user-line", "user-outline", "user-search", "user", "user2", "video", "vote-down", "vote-up", "warn", "warn2", "warn3", "warranty-screen", "x", "youtube", "zoom-in", "zoom-out", "zoom", ;