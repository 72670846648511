.row-select {
	float:none;
	clear: both;
	margin-top:10px;
	label{
		display: inline-block;
		vertical-align: top;
		margin:5px 10px 0 10px;

	}
	.select3-container{
		display:inline-block;
		width:500px;
	}
}
.print-mode{
	#rodo{
		display:none;
	}
}
#wc-rodo-header{
	display:none;
	font-size:16px;
}
@media print {
	.print-mode{
		#breadcrumb, .js-print, #lp-sidebar, #lp-footer, .lp-headings, .row-select, h4,ol, p, .wrapper-sticky{
			display:none !important;
		}
		#lp-content{
			width:100% !important;
		}
		#wc-rodo-header{
			display:block !important;
		}
		#rodo {
			display:block !important;
			h4, p{
				display:block !important;
				color:#000;
				font-size:14px;
			}
			button, #salesman-number{
				display:none !important;
			}
		}
		.row .column{
			padding-left:0 !important;
		}

	}
	.app-katalog.print-mode{
		.send-success{
			display: none;
		}
	}
}

.send-success{
	border:1px solid #8bc34a;
	&:before{
		color:#8bc34a;
		border:1px solid #8bc34a;
		@extend %icon-check;
	}

}
.send-error{
	border:1px solid red;
	&:before{
		color:red;
		@extend %icon-exclamation-triangle;
	}
}
.send-success, .send-error{
	padding:20px 10px 20px 60px;
	position: relative;
	margin:20px 0;
	font-size: 18px;
	&:before{
		position: absolute;
		left: 18px;
		top: 15px;
		font-size:18px;
		width:30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 50%;
	}
}
#rodo-print{
	@extend %btn-primary;
}
#lp-info #rodo {
	&.loading{
		@extend %loading;
	}
	.col{
		float:left;
		width:436px;
		&:first-child{
			border-right:1px dotted #333;
			padding-right: 20px;
			margin-right:20px;
		}
		.row{
			width:409px;
			.row-houseNumber, .row-flatNumber{
				width:199px;
			}
		}
	}
	.row{
		padding:0 0 3px;
		margin:0;
		float: none;
		clear: both;
		width:534px;
		overflow: hidden;
		.row-houseNumber, .row-flatNumber{
			float:left;
			width:262px;
			clear: none;
		}
		.row-houseNumber{
			margin-right: 10px;
		}
		.validate-error{
			//padding:8px 0;
			margin:2px 0;
		}
	}

	input[type="text"]{
		border:1px solid $border-color;
		padding: 0 10px;
		height:36px;
		line-height: 34px;
		vertical-align: middle;
		font-size:16px;
		width:100%;
		background: #fff;
	}
	input[type="date"]{
		border:1px solid $border-color;
		padding: 0 10px;
		height:36px;
		line-height: 34px;
		vertical-align: middle;
		font-size:16px;
		width:100%;
		background: #fff;
		color: #1C1C1B;
		box-sizing: border-box;
		&:focus{
			outline: 0;
		}
	}
	textarea{
		border:1px solid $border-color;
		padding: 5px 10px;
		height:106px;
		line-height: 1.35em;
		font-size:16px;
		width:100%;
		background: #fff;
	}
	&[data-type="correct"]{
		.required{
			text-align:center;
		}
		button{
			margin: 0 auto;
			display: block;
		}
	}
	.required{
		clear: both;
		font-size:11px;
		padding:10px 0 20px;
	}
	button{
		@extend %btn-primary;
	}
}
#rodo-message{
	width:640px;
	font-size:15px;
	.buttons{
		margin-top:10px;
	}
	.back{
		@extend %btn-primary;
	}
	.send{
		margin-right:10px;
		@extend %btn-fourth;
	}
}
