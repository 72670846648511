@if($app-katalog==false){
	#refund-page {
		padding: 20px;
		font-size: 13px;



		.row {
			padding: 5px 10px;
			width: 850px;
		}

		& .row > label:not(.radio-css):not(.checkbox-css) {
			font-size: 14px;
			width: 35%;
		}

		.header-label {
			display: block;
			font-weight: bold;
			margin-bottom: 40px;
			margin-top: 10px;
		}

		.field-box {
			width: 60%;
		}

		a {
			text-decoration: underline;
		}

		p {
			margin-bottom: 15px;
		}

		.center {
			text-align: center;
		}

		.btn {
			text-decoration: none;
		}

		.added-product-list {
			margin-bottom: 20px;
			margin-top: 10px;
			margin-left: 37%;
			padding-left: 0;

			li {
				list-style-type: none!important;
				margin-left: 0 !important;
			}
		}

		ol,
		ul {
			padding-left: 20px;
			margin-bottom: 15px;
		}

		ul li {
			list-style: disc;
		}

		.title {
			padding: 0 20px;
			font-weight: $font-weight-bold;
			font-size: 24px;
			margin-bottom: 5px;
		}

		.breadcrumb {
			padding: 0 20px;
		}

		.double-row {
			margin-top: 0;
			padding-top: 0;
		}

		#number-row {
			padding: 5px 10px 20px;

			input {
				width: 200px;
			}
		}

		#product-name-row {}

		#date-row {
			.field-box {
				position: relative;
			}

			img {
				cursor: pointer;
				position: absolute;
				top: 5px;
				left: 110px;
			}
		}

		#brand-item-0 {
			color: red;
		}

		#date-row {
			input {
				width: 100px;
			}
		}

		#phone-row {
			input {
				width: 200px;
			}
		}

		#street-row {
			input {
				width: 200px;
			}
		}

		#zipCode-row {
			input {
				width: 90px;
			}
		}

		#account-row {
			input {
				width: 500px;
			}
		}

		#account-label {
			display: block;
			margin: 0 0 3% 37%;
			text-align: left;
			width: 63%;
		}

		#refund-house-number {
			width: 50px;
		}

		label[for="refund-flat-number"]:not(.error) {
			margin-left: 5px;
			margin-right: 5px;
		}

		#refund-flat-number {
			width: 54px;
		}

		.add-item {
			@extend %btn;
			@extend %btn-third;
			margin-left: 37%;
		}

		.remove-item {
			margin-left: 15px;
			text-decoration: none;
		}
	}
}
