@import "../common/general/variables";

$font-url:'/font/desktop/euro/';
$font-name:'Lato';
$font-regular:'Regular';
$font-bold:'Heavy';
$font-italic:'Italic';
$font-light:'Light';

$accent-color: #00A551;

$text-color					: #1C1C1B;	//tekst podstawowy
$text-color-lighter			: #999999;	//text pomocniczy
$text-color-error			: #e30613;	//text błędu

$link-color					: #005CA9;	//linki
$link-color-hover			: #2B77B8;	//linki

$novelty-color				: #58ad33;  //kolor statusu nowości
$promotion-color			: #d32f36;  //kolor statusu promocji
$sale-color					: #45abe5;  //kolor statusu wyprzedaży
$bestseller-color			: #45abe5;  //kolor statusu bestseller
$advance-sale-color			: #c71585;  //kolor statusu przedsprzedaz

$loader-color				: #005CA9;	//kolor animacji kręciołka
$filter-selected			: #fffbcc;	// kolor aktywności w bloczku filtrowania

$outlet-color: #3b80ba;

$checkbox-color				: #005CA9;	//kolor checkboxa
$checkbox-background: #FFE843;
$checkbox-active-background: #3c3b3b;
$checkbox-text-color: #3c3b3b;
$checkbox-active-text-color: $checkbox-background;
$checkbox-check-color: $checkbox-background;
$checkbox-check-background: #111;

$instalment-slider: $link-color;

$delivery-info-color: $accent-color;

$promotion-bar-background: #063F95;
$menu-bar-background: #FFE843;

$new-menu-bar-background: #076AE5;
$new-menu-bar-active-background: #2184FE;

$accessories-active-background: $filter-selected;
$paging-active-background: $filter-selected;
$extended-warranty-background: $filter-selected;
$call-btn-active-background: $filter-selected;
$clipboard-btn-active-background: $filter-selected;
$compare-btn-active-background: $filter-selected;
$opinion-info-background: $filter-selected;
$menu-product-active: $link-color;

//ciemne tło: #3C3B3B;
//szare tło: #eaeaea

$button-primary-background			: #ffe843;																	//button primary
$button-primary-color				: #1C1C1B;																	//kolor button primary
$button-primary-background-hover	: #ffef5f;																	//button primary hover
$button-primary-color-hover			: #1C1C1B;																	//kolor button primary hover

$button-second-background			: #ffe843;																	//button second
$button-second-color				: #1C1C1B;																	//kolor button second
$button-second-background-hover		: #ffef5f;																	//button second hover
$button-second-color-hover			: #1C1C1B;																	//kolor button second hover

$button-third-background			: #104790;																	//button seventh
$button-third-color					: #ffffff;																	//kolor button seventh
$button-third-background-hover		: #2960a8;																	//button seventh hover
$button-third-color-hover			: #ffffff;																	//kolor button seventh hover

$button-fourth-background			: #595959;																	//button third
$button-fourth-color				: #fff;																		//kolor button third
$button-fourth-background-hover		: #7c7c7c;																	//button third hover
$button-fourth-color-hover			: #fff;																		//kolor button third hover

$background-alert					: #E63327;																	//button alert
$background-alert-hover				: #FF372A;																	//button alert hover

$button-disabled-background			: #eee;
$button-disabled-color				: #999;

$border-color						: #CCCCCC;																	//kolor obramowania

$img-url: '/img/desktop/euro/';

$font-default: 'Lato', sans-serif;
$font-default-size: 12px;
$font-default-line-height: 1.2;

$default-box-shadow-horizontal:0;
$default-box-shadow-vertical:0;
$default-box-shadow-blur:4px;
$default-box-shadow-color: rgba(0,0,0,0.25);

$breakpoint-large: 1230;
$breakpoint-midium: 980;
$breakpoint-large-width: 1200;
$breakpoint-midium-width: 960;
$breakpoint-midium-height: 760;

$default-box-border-radius:4px;

@import "../katalog/variables";