.form-default {
	button{
		border:0;
	}
	.send-error{
		padding:30px 0;
		font-size:20px;
		text-align:center;
		color:red;
		background:#FCF2F2;
	}
	.send-success{
		padding:20px 0;
		font-size:18px;
		text-align:center;
		background:#E5FBFC;
		font-weight: bold;
		p {
			font-size: 14px;
			margin-top: 20px;
		}
	}
	.validate-target{
		&.error{
			background:#FAD6D4;
			input{
				border-color: #e63327;
			}
			textarea{
				border-color: #e63327;
			}
			.radio-css input[type="radio"] + i::before {
				border-color: #e63327;
			}

			.checkbox-css input[type="checkbox"] + i {
				border-color: #e63327;
			}
			span.error {
				margin-top: 6px;
				color: #ED1C24;
				display: block;
				font-weight: bold;
				clear: both;
			}
		}
	}
	padding: 20px 0;
	text-align: center;
	.row {
		margin: 7px auto 0;
		padding:7px 10px;
		width:690px;
		overflow: hidden;
		> label:not(.radio-css, .checkbox-css) {
			width: 40%;
			margin-right:2%;
			float: left;
			text-align: right;
			display: block;
			font-size: 16px;
			box-sizing: border-box;
			min-height: 23px;
			line-height: 1.6!important;
		}
	}
	.hidden-input {
		display: none;
	}
	.radio-css {
		float: left;
		margin-right: 5px!important;
	}
	.checkbox-css {
		float: left;
		margin-right: 5px!important;
	}
	.radio-label {
		float: left;
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}
	.checkbox-label {
		float: left;
		margin-right: 20px;
	}
	.field-box {
		width: 50%;
		float: left;
		display: block;
		box-sizing: border-box;
		label.error {
			width: 100%!important;
			text-align: left!important;
			font-size: 12px!important;
			font-weight:$font-weight-bold;
			color: #e63327;
			display:block;
			clear: both;
		}
		label {
			margin-bottom: 5px;
			display: inline-block;
		}

		&.vertical-set {

			.radio-css, .checkbox-css {
				clear: left;
			}

			label {
				margin: 5px 0;
				display: inline-block;
			}
		}
	}

	.check-box {
		text-align: center;
		width: auto;

		.checkbox-css {
			float: none;
		}
	}
	input:not([type="radio"], [type="file"]) {
		border: 1px solid $border-color;
		height: 26px;
		padding: 5px;
		width: 250px;
		box-sizing: border-box;
	}
	.radio-label {
		font-size: 16px;
		float: left;
	}
	textarea {
		border: 1px solid $border-color;
		height: 100px;
		padding: 5px;
		width: 100%;
		box-sizing: border-box;
	}
	button {
		@extend %btn-primary;
	}
	.tooltip-icon::before {
		margin-left: 5px;
		vertical-align: middle;
	}
	.row.middle-set {

		display: table;

		> label {
			float: none !important;
			display: table-cell !important;
			padding-right: 2%
		}

		.field-box {
			display: table-cell !important;
			vertical-align: middle;
			float: none;
			width: 56%;
		}
	}
}
